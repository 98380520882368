#nav {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	text-align: center;
	font-family: 'Cinzel', serif;

}

.nav__logo {
	padding: 25px 50px;

	img {
		max-width: 150px;
	}
}

.nav__links {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: flex-end;
	margin-right: 50px;
	font-size: 1.25rem;
}

.nav__links--link {
	margin: 0 10px;
	text-decoration: none;
	color: white;
	font-weight: 600;
}

.nav__links--link-dropdown {
	margin: 10px 0;
}

.dropdown {
	position: absolute;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	margin-top: 250px;
	background-color: #152e92ea;
	border-radius: 10px;
	padding: 20px;
	z-index: 10000;
}

#about-link {
	display: flex;
	flex-direction: column;
	justify-content: space-evenly;
	align-items: center;
	color: white;
	font-weight: 600;

	margin: 0 10px;
	cursor: pointer;
}

@media (max-width: 1024px) {
	.nav__logo {
		padding: 25px 0px;
		margin-left: 50px;

	}
}

@media (max-width: 425px) {
	#nav {
		display: none !important;
	}
}
