.footer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding-bottom: 400px;
  margin-top: -50px;

  background: url("../../assets/home/footer.png");
  background-repeat: no-repeat;
  background-size: cover;
}

.footer__content {
  transform: translateY(80%);
  color: white;

  p {
    font-weight: 500;
    font-size: 16px;
    line-height: 37px;
    text-align: center;
    color: #ffffff;
  }

  a {
    text-decoration: none;
    color: white;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    text-align: center;
    color: #ffffff;
  }
}

.footer__social {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-right: 25px;
  img {
    margin: 0 15px;
  }
}
