.events {
	background: white;
}

.events--headerbg {
	background-image: url('../../assets/contact/bg.png');
	background-size: cover;
	background-repeat: no-repeat;
}

.events-header {
	display: flex;
	flex-direction: row;
	justify-content: space-evenly;
	align-items: center;
	text-align: center;
}

.events-header__text {
	text-align: center;

	h1 {
		font-style: normal;
		font-weight: 600;
		font-size: 42px !important;
		line-height: 125% !important;
		/* or 45px */
		color: #ffffff;
		text-align: center;
		margin-bottom: 50px;
	}
}

.events-header__image {
	max-height: none;
	max-width: none;
}

.events_button {
	background: linear-gradient(180deg, #2189c9 0%, #387cc7 100%);
	border: 2px solid #2d82c8;
	box-sizing: border-box;
	box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.25);
	border-radius: 4px;
	font-size: 18px;
	font-weight: 600;
	padding: 10px 25px;
	width: fit-content;
	transition: 0.25s ease-in-out;
	margin: auto;

	&:hover {
		transform: scale(0.95);
		box-shadow: none;
	}

	a {
		text-decoration: none;
		color: white;
	}
}

.events__subhead {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	text-align: center;

	span {
		font-style: normal;
		font-weight: 800;
		font-size: 20px;
		line-height: 126%;
		text-align: center;
		color: #000000;
	}

	h2 {
		font-style: normal;
		font-weight: 800;
		font-size: 42px;
		line-height: 126%;
		text-align: center;
		color: #000000;
	}
}

@media (max-width: 1024px) {
	.events-header__text {
		text-align: start !important;
		padding-left: 100px;

		h1 {
			font-size: 36px !important;
		}
	}
}

@media (max-width: 768px) {

	.events-header {
		flex-direction: column;
		padding-top: 100px;
		padding-bottom: 100px;

		img {
			max-width: 400px;
		}
	}

	.events-header__text {
		padding-left: 0;
		h1 {
			text-align: center !important;
		}
	}

	.events_button {
		margin: auto;
	}
}

@media (max-width: 425px) {
	.events-header {
		flex-direction: column;
		padding-top: 100px;

		img {
			max-width: 400px;
		}
	}

	.events-header__text {
		h1 {
			text-align: center !important;
		}
	}

	.events_button {
		margin: auto;
	}
}
