html {
	background-color: rgba(36, 166, 223, 0.2);
	
}

body {
	max-width: 1440px;
	background-color: white;
	margin: 0 auto;
	}

body,
html,
.body {
	overflow-x: hidden;
}

h1, h2, h3, h4, h5, .about__subtitle, .connect--subtitle, .join--subhead {
	font-family: 'Cinzel', serif;
}
