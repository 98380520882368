//HERO

.top-bg {
	background-image: url('../../assets/home/hero-bg.png');
	background-size: cover;
	background-repeat: no-repeat;
}

.hero {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	padding-bottom: 75px;
}

.hero__text {
	padding-left: 50px;
	margin-top: -75px;
	text-align: justify;
}

.hero__text--title {
	font-weight: bold;
	font-size: 48px;
	line-height: 111%;
	letter-spacing: 0.05em;
	color: #ffffff;
	text-align: center;
}

.darkblue {
	color: #51c2f3;
}

.hero__text--body {
	font-size: 18px;
	line-height: 30px;
	color: #f5fbff;
	margin-top: -25px;
}

.hero__image {
	img {
		max-width: 750px;
	}
}

.button {
	padding: 15px;
	text-decoration: none;
	color: white;
	font-weight: 500;
	font-size: 20px;
	transition: 0.25s ease-in-out;
	cursor: pointer;

	&:hover {
		transform: scale(0.95);
		box-shadow: none;
	}
}

.hero-button {
	background: #51c2f3;
	box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
	padding: 15px;
	text-decoration: none;
	color: white;
	font-weight: 500;
	font-size: 20px;
	width: 150px;
	text-align: center;
	transition: 0.25s ease-in-out;
	cursor: pointer;
	margin: auto;

	&:hover {
		transform: scale(0.95);
		box-shadow: none;
	}
}

.hero__image--mobile {
	display: none;
}

@media (max-width: 1024px) {
	.top-bg {
		padding-bottom: 75px;
	}
	.hero {
		margin-top: 80px;
		
	}
	.hero__image {
		img {
			max-width: 500px;
		}
	}

	.hero__text--title {
		font-weight: bold;
		font-size: 42px;
		line-height: 111%;
		letter-spacing: 0.05em;
		color: #ffffff;
	}

	.hero__image--mobile {
		display: none;
	}
}

@media (max-width: 768px) {
	.hero {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		padding-bottom: 75px;
		text-align: center;
	}

	.hero__text {
		padding: 0 50px;
		margin-bottom: 50px;

		p {
			margin: 10px 0;
		}
	}

	.hero-button {
		margin: auto;
		margin-top: 30px;
	}

	.hero__image {
		display: none;
	}

	.hero__image--mobile {
		display: flex;
		justify-content: center;
		align-items: center;
		

		img {
			max-width: 450px;
			padding: 20px;
			transform: translateX(5%);
		}
	}
}

@media (max-width: 425px) {
	.hero__image--mobile {
		margin: auto;
		img {
			max-width: 350px;
			margin-bottom: 25px;
			transform: translateX(5%);
		}
	}

	.hero__text {
		margin-top: 0;
		padding: 15px;
	}
}

//About

.about {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	margin-top: -125px;

	background: url('../../assets/home/about-bg.png');
	background-position: top right;
	background-repeat: no-repeat;
	background-size: 400px;
}

.about__content {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
}

.about__title {
	font-weight: bold;
	font-size: 55px;
	line-height: 126%;
	text-align: center;
	letter-spacing: 0.04em;
	color: #2569b0;
	margin-top: -50px;
}

.about__subtitle {
	font-weight: bold;
	font-size: 100px;
	text-align: center;
transform: translateY(125%);	color: rgba(36, 166, 223, 0.03);
}

.about__content--image {
	img {
		max-width: 500px;
		transform: scale(1.25) ;
	}
}

.text--title {
	font-size: 35px;
	line-height: 144%;
	letter-spacing: 0.04em;
	color: #696969;
}

.blue {
	font-weight: 900;
	font-size: 45px;
	line-height: 144%;
	letter-spacing: 0.04em;
	color: #2569b0;
}

.about__content--text {
	margin-top: -50px;
	p {
		font-size: 21px;
		line-height: 36px;
		color: #797979;
		width: 75%;
		margin: 100px auto 50px auto;

	}
}

.about-button {
	background: #51c2f3;
	box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
	padding: 15px;
	text-decoration: none;
	color: white;
	font-weight: 500;
	font-size: 20px;
	width: 150px;
	text-align: center;
	transition: 0.25s ease-in-out;
	cursor: pointer;
	margin: auto;


	&:hover {
		transform: scale(0.95);
		box-shadow: none;
	}
}

.mobile-text {
	display: none;
}

@media (max-width: 1024px) {
	.about__content--text {
		padding-left: 10px;
		padding-right: 75px;
		margin: auto;

		p {
			width: unset;
			font-size: 18px;
		}

	}

	.about{
		background-size: 300px;
	}

	.about__content--image {
		img {
			max-width: 500px;
			transform: scale(1) translate(-10%, -5%);
		}
	}
}

@media (max-width: 768px) {

	.about {padding: 100px 0 0 0;}

	.about__content {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		text-align: center;
	}

	.about__subtitle {
		font-size: 75px;
	}

	.about-button {
		margin: auto;
	}

	.about__content--text {
		padding: 0 25px;
		margin-top: 0px;
	}

	
}

@media (max-width: 425px) {
	.about {
		background-size: 250px;
		padding: unset;
	}

	.text--title {
		font-size: 24px;
		margin-top: -100px;

		span {
			font-size: 36px;
		}
	}

	.about__content--text {

		p {
			padding: 0 35px;
			text-align: justify;
		}
	}

	.mobile-text {
		display: block !important;
	}

	.desktop-text {
		display: none !important;
	}
}

//CONNECT

.connect {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;

	background-image: url('../../assets/home/connect.png');
	background-size: cover;
	background-repeat: no-repeat;
	padding-bottom: 250px;
	margin-top: 75px;
}

.connect--title {
	font-weight: bold;
	font-size: 55px;
	line-height: 126%;
	text-align: center;
	letter-spacing: 0.04em;
	color: #fff;
	margin-top: -180px;
	padding-bottom: 50px;
}

.connect--subtitle {
	font-weight: bold;
	font-size: 100px;
	line-height: 121%;
	text-align: center;
	color: rgba(255, 255, 255, 0.06);
	margin-top: 50px;
}

.connect__content {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
}

.connect__content--video {
	transform: translateY(15%);
	cursor: pointer;
}

.connect__content--text {
	font-weight: bold;
	font-size: 28px;
	line-height: 132%;
	text-align: center;
	letter-spacing: 0.04em;
	color: #ffffff;

	h3 {
		margin-left: 50px;
	}
}

.arrow {
	position: absolute;
	left: 50%;
	top: 1550px;
}

.connect-social {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;

	img {
		margin: auto 15px;
	}
}

.connect--yt {
	margin-bottom: 40px;
	padding-left: 25px;
}

@media (max-width: 1024px) {
	#videoImage {
		max-width: 500px !important;
	}

	.arrow {
		position: absolute;
		left: 50%;
		top: 1600px;
	}

	.connect__content--text h3 {
		font-size: 28px;
	}
}

@media (max-width: 768px) {
	.connect__content {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		margin-top: -100px;
	}

	.connect__content--text {
		margin-top: 50px;

		h3 {
			margin-left: 0px;
		}
	}

	.connect {
		padding-bottom: 500px;
		margin-top: 75px;
	}

	.connect__social {
		padding-right: 25px;
	}

	.arrow {
		display: none;
	}
}

@media (max-width: 425px) {

	
	#videoImage {
		max-width: 350px !important;
	}

	iframe {
		max-width: 300px;
		max-height: 200px;
	}

	.connect__content--text {
		margin-top: 30px;

		h3 {
			margin-left: 0px;
		}
	}

	.connect--subtitle {
		font-size: 50px;
		margin-bottom: 10px;
	}
	.connect--title {
		font-size: 36px;
		margin-top: -50px;
		padding-bottom: 100px;
	}
}

//JOIN

#notes {
	position: absolute;
	transform: translateY(-50%);
}

.join {
	display: flex;
	flex-direction: column !important;
	justify-content: center;
	align-items: center;
}

.join--subhead {
	font-weight: bold;
	font-size: 100px;
	line-height: 121%;
	text-align: center;
	color: rgba(36, 166, 223, 0.03);
	margin-top: 50px;
}

.join--head {
	font-weight: bold;
	font-size: 55px;
	line-height: 126%;
	text-align: center;
	letter-spacing: 0.04em;
	color: #2569b0;
	margin-top: -180px;
}

.join__text {
	p {
		font-weight: 500;
		font-size: 25px;
		line-height: 37px;
		text-align: center;
		color: #444647;
	}
}

.quote-start {
	transform: translateX(-200%);
}

.quote-end {
	transform: translateX(1250%);
}

@media (max-width: 1024px) {
	#notes {
		position: absolute;
		transform: translateY(-70%) scale(0.6);
	}

}

@media (max-width: 768px) {
	#notes {
		position: absolute;
		transform: translateY(-105%) ;
		width: 350px;
	}
	.join {
		margin-top: -100px;
	}
	.quote-end {
		transform: translateX(1200%);
	}

	.join--subhead {
		font-size: 75px;
	}
}

@media (max-width: 425px) {
	#notes {
		position: absolute;
		transform: translate(70%, -70%);
		width: 250px;
	}

	.join {
		margin-top: -250px;
	}

	.join--head {
		margin-top: -75px;
		font-size: 42px;
	}

	.join--subhead {
		font-size: 50px;
		transform: translateY(100%);
	}

	.join__text {
		p {
			font-weight: 500;
			font-size: 21px;
			line-height: 37px;
			text-align: center;
			color: #444647;
			width: 90%;
			margin: auto;
		}
		margin-bottom: -75px;
	}
}

//FOOTER

.footer {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	text-align: center;
	padding-bottom: 400px;
	margin-top: -50px;

	background: url('../../assets/home/footer_bg.png');
	background-repeat: no-repeat;
	background-size: cover;
}

.footer__content {
	transform: translateY(80%);
	color: white;

	p {
		font-weight: 500;
		font-size: 16px;
		line-height: 37px;
		text-align: center;
		color: #ffffff;
	}

	a {
		text-decoration: none;
		color: white;
		font-weight: 500;
		font-size: 14px;
		line-height: 21px;
		text-align: center;
		color: #ffffff;
	}
}

.footer__social {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	margin-right: 25px;
	img {
		margin: 0 15px;
	}
}
