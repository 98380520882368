.slider {
	margin-top: 25px;
}

.slider__container {
	margin: 0 100px;
}

.slider__container--slide {
	justify-content: center;
	align-items: center;
	text-align: center;
	display: flex;
	flex-direction: column;
	margin: 50px 0;

	img {
		max-width: 150px;
		margin: auto;
	}
}

.center {
	background: white;
	box-shadow: 0px 25px 40px rgba(36, 166, 223, 0.21);
	border-radius: 5px;
}

.shrink {
	transform: scale(0.925);
}

.slide--name {
	font-weight: 500;
	font-size: 30px;
	line-height: 155.6%;
	text-transform: capitalize;
	color: #2569b0;
	margin-top: 0px;
}

.slide--text {
	font-weight: normal;
	font-size: 18px;
	line-height: 155.6%;
	text-align: center;
	text-transform: capitalize;
	color: #a2a1a1;
	margin-top: -30px;
	padding: 0 25px;
}
.slick-active button::before {
	color: #2569b0 !important;
}

@media (max-width: 1024px) {
	.slide--text {
		font-weight: normal;
		font-size: 14px;
		line-height: 155.6%;
		text-align: center;
		text-transform: capitalize;
		color: #a2a1a1;
		margin-top: -30px;
		padding: 0 15px;
	}

	.slider__container {
		margin: 0 50px;
	}
}

@media (max-width: 768px) {
	.center {
		background: white;
		box-shadow: none;
		border-radius: 5px;
	}

	.slide--text {
		font-weight: normal;
		font-size: 16px;
		line-height: 155.6%;
		text-align: center;
		text-transform: capitalize;
		color: #a2a1a1;
		margin-top: -30px;
		padding: 0 15px;
	}
}

@media (max-width: 425px) {
	.slider {
		margin-bottom: -75px;
	}
}
