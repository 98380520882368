#mobile-nav {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
}

.mobile-nav__logo {
	padding: 25px;
	img {
		max-width: 150px;
	}
}

#menuToggle {
	display: block;
	position: relative;
	top: 50px;
	left: 350px;
	z-index: 1;

	-webkit-user-select: none;
	user-select: none;
}

#menuToggle a {
	text-decoration: none;
	color: white;
	font-family: 'Cinzel', serif;

	transition: color 0.3s ease;
}

#about-link-mobile {
	color: white;
	font-family: 'Cinzel', serif;
	
}

#menuToggle a:hover {
	color: whitesmoke;
}

#menuToggle input {
	display: block;
	width: 40px;
	height: 32px;
	position: absolute;
	top: -7px;
	left: -5px;

	cursor: pointer;

	opacity: 0; /* hide this */
	z-index: 2; /* and place it over the hamburger */

	-webkit-touch-callout: none;
}

/*
 * Just a quick hamburger
 */
#menuToggle span {
	display: block;
	width: 27px;
	height: 2px;
	margin-bottom: 5px;
	position: relative;

	background: #fff;
	border-radius: 3px;

	z-index: 1;

	transform-origin: 4px 0px;

	transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1),
		background 0.5s cubic-bezier(0.77, 0.2, 0.05, 1), opacity 0.55s ease;
}

#menuToggle span:first-child {
	transform-origin: 0% 0%;
}

#menuToggle span:nth-last-child(2) {
	transform-origin: 0% 100%;
}

/* 
 * Transform all the slices of hamburger
 * into a crossmark.
 */
#menuToggle input:checked ~ span {
	opacity: 1;
	transform: rotate(45deg) translate(-2px, -1px);
	background: #fff;
}

/*
 * But let's hide the middle one.
 */
#menuToggle input:checked ~ span:nth-last-child(3) {
	opacity: 0;
	transform: rotate(0deg) scale(0.2, 0.2);
}

/*
 * Ohyeah and the last one should go the other direction
 */
#menuToggle input:checked ~ span:nth-last-child(2) {
	transform: rotate(-45deg) translate(0, -1px);
}

/*
 * Make this absolute positioned
 * at the top left of the screen
 */
#menu {
	position: absolute;
	width: 450px;
	height: 100vh;
	margin: -100px 0 0 -350px;
	padding: 50px;
	padding-top: 125px;

	background: url('../../assets/nav/mobile-bg.png'), rgba($color: #fff, $alpha: 0.85);
	list-style-type: none;
	-webkit-font-smoothing: antialiased;
	/* to stop flickering of text in safari */

	transform-origin: 0% 0%;
	transform: translate(150%, 0);

	transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1);
}

#menu li {
	padding: 10px 0;
	font-size: 22px;
}

/*
 * And let's slide it in from the left
 */
#menuToggle input:checked ~ ul {
	transform: none;
}

.nav__links--mobile {
	display: flex;
	flex-direction: column;
	justify-content: space-evenly;
	align-items: center;
	margin-right: 120px;
	margin-top: -50px;
}

.nav__links--mobile-link {
	margin: 50px 0;
	font-size: 28px;
}

.dropdown-mobile {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	text-align: center;
}

.nav__links--link-dropdown-mobile {
	color: #fff !important;
	font-weight: 600;
	margin: 10px 0;
}

.mobile-logo {
	position: absolute;
	padding: 30px 0 0 50px;
	img {
		max-width: 150px;
	}
}

@media (min-width: 426px) {
	#mobile-nav {
		display: none !important;
	}
}

@media (max-width: 768px) {
	.mobile-logo {
		position: absolute;
		padding: 100px 0 0 50px;
		img {
			max-width: 150px;
		}
	}
}
