.about-section__header {
	display: flex;
	flex-direction: row;
	justify-content: space-evenly;
	align-items: center;
	text-align: center;
}

.about-section__bg {
	background-image: url('../../assets/contact/bg.png');
	background-size: cover;
	background-repeat: no-repeat;
	padding-bottom: 200px;
}

.about-section__header {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	text-align: center;
	transform: translateY(50%);
	img {
		max-height: 5px;
		margin: 0 25px;
	}

	h1 {
		font-weight: bold;
		font-size: 52px;
		text-align: center;
		color: #ffffff;
	}
}

.about-section__subhead {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	text-align: center;

	span {
		font-style: normal;
		font-weight: 800;
		font-size: 20px;
		line-height: 126%;
		text-align: center;
		color: #000000;
	}

	h2 {
		font-style: normal;
		font-weight: 800;
		font-size: 42px;
		line-height: 126%;
		text-align: center;
		color: #000000;
	}
}

.about-section__content {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	padding: 50px 100px;
	margin-top: 50px;
}

.about-section__content__image {
	img {
		max-width: 500px;
	}
}

.about-section__content__text {
	padding-left: 50px;

	h2 {
		font-style: normal;
		font-weight: bold;
		font-size: 38px;
		line-height: 106%;
		/* or 40px */

		color: #081624;
		margin-bottom: 0px;
	}

	p {
		font-style: normal;
		font-weight: normal;
		font-size: 17px;
		line-height: 152%;
		/* or 26px */

		color: #000000;
	}
}

@media (max-width: 1024px) {
	.about-section__content {
		padding: 50px 10px;

		img {
			max-width: 400px;
		}
	}
}

@media (max-width: 768px) {	
	.about-section__header {
		padding-top: 50px;
		h1 {
			font-size: 42px;
		}

		img {
		width: 100px;		}
	}

	.about-section__content {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		padding: 0px 0px;
		margin-top: 50px;
	}
	.about-section__content__text {
		padding: 50px 15px !important;
	}

	.about-section__content__image {
		img {
			max-width: 350px;
		}
	}
}
