.perf-img {
	width: 250px;
	height: 400px;
	margin: auto;
	filter: drop-shadow(0px 7px 14px rgba(0, 0, 0, 0.35));
}

.container {
	text-align: center;
	padding: 50px 0;
}

.slick-prev,
.slick-next {
	transform: scale(2) translateY(-100%);
}
